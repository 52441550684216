import StoreManager from "../appdata";
import { message } from 'antd';
import {jwtDecode} from "jwt-decode";

const API_URL = 'https://apitruonguy.demngayyeu.vn'; // Địa chỉ của server
// const API_URL = 'http://localhost:8090'; // Địa chỉ của server

export const loginAPI = (username, password) => {
  fetch(`${API_URL}/api/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      username: username,
      password: password,
    })
  })
  .then(response => response.json())
  .then(data => {
    if (data && data.token != null) {
      setTimeout(() => {
        StoreManager.userdata.token = data.token;
        StoreManager.userdata.jwtDecode = jwtDecode(data.token);
        console.log("StoreManager.userdata.jwtDecode", StoreManager.userdata.jwtDecode);
        StoreManager.updateDataToLocal();
        message.open({
          type: 'success',
          content: `Login Success`,
        });
        StoreManager.rootState.thisApp.setState({ isLogin: true });
      }, 1000);
    } else {
      message.open({
        type: 'error',
        content: `${data.message}`,
      });
      StoreManager.rootState.thisLogin.setState({ isLoading: false });
    }
  })
  .catch(error => {
    StoreManager.rootState.thisLogin.setState({ isLoading: false });
    console.error('Error:', error);
  });
}

// Function to get the authorization headers
const getAuthHeaders = () => ({
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${StoreManager.userdata.token}`
});

// Companies API
export const getCompanies = () => {
  return fetch(`${API_URL}/api/companies`, {
    method: 'GET',
    headers: getAuthHeaders()
  }).then(response => response.json());
}

export const createCompany = (company) => {
  return fetch(`${API_URL}/api/companies`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(company)
  }).then(response => response.json());
}

export const updateCompany = (id, company) => {
  return fetch(`${API_URL}/api/companies/${id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(company)
  }).then(response => response.json());
}

export const deleteCompany = (id) => {
  return fetch(`${API_URL}/api/companies/${id}`, {
    method: 'DELETE',
    headers: getAuthHeaders()
  }).then(response => response.json());
}

// TagGroups API
export const getTagGroups = () => {
  return fetch(`${API_URL}/api/tagGroups`, {
    method: 'GET',
    headers: getAuthHeaders()
  }).then(response => response.json());
}

export const createTagGroup = (tagGroup) => {
  return fetch(`${API_URL}/api/tagGroups`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(tagGroup)
  }).then(response => response.json());
}

export const updateTagGroup = (id, tagGroup) => {
  return fetch(`${API_URL}/api/tagGroups/${id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(tagGroup)
  }).then(response => response.json());
}

export const deleteTagGroup = (id) => {
  return fetch(`${API_URL}/api/tagGroups/${id}`, {
    method: 'DELETE',
    headers: getAuthHeaders()
  }).then(response => response.json());
}

// Tags API
export const getTags = () => {
  return fetch(`${API_URL}/api/tags`, {
    method: 'GET',
    headers: getAuthHeaders()
  }).then(response => response.json());
}



export const createTag = (tag) => {
  return fetch(`${API_URL}/api/tags`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(tag)
  }).then(response => response.json());
}

export const updateTag = (id, tag) => {
  return fetch(`${API_URL}/api/tags/${id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(tag)
  }).then(response => response.json());
}

export const deleteTag = (id) => {
  return fetch(`${API_URL}/api/tags/${id}`, {
    method: 'DELETE',
    headers: getAuthHeaders()
  }).then(response => response.json());
}

export const getTagById = (tagId) => {
  return fetch(`${API_URL}/api/tags/${tagId}`, {
    method: 'GET',
    headers: getAuthHeaders(),
  }).then(response => response.json());
}


// Logs API
export const getLogsByTagId = (tag_id) => {
  return fetch(`${API_URL}/api/logs/tag/${tag_id}`, {
    method: 'GET',
    headers: getAuthHeaders()
  }).then(response => response.json());
};

export const getLogs = () => {
  return fetch(`${API_URL}/api/logs`, {
    method: 'GET',
    headers: getAuthHeaders()
  }).then(response => response.json());
}

export const createLog = (log) => {
  return fetch(`${API_URL}/api/logs`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(log)
  }).then(response => response.json());
}

export const updateLog = (id, log) => {
  return fetch(`${API_URL}/api/logs/${id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(log)
  }).then(response => response.json());
}

export const deleteLog = (id) => {
  return fetch(`${API_URL}/api/logs/${id}`, {
    method: 'DELETE',
    headers: getAuthHeaders()
  }).then(response => response.json());
}

// Users API
export const getUsers = () => {
  return fetch(`${API_URL}/api/users`, {
    method: 'GET',
    headers: getAuthHeaders()
  }).then(response => response.json());
}

export const createUser = (user) => {
  return fetch(`${API_URL}/api/users`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(user)
  }).then(response => response.json());
}

export const updateUser = (id, user) => {
  return fetch(`${API_URL}/api/users/${id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(user)
  }).then(response => response.json());
}

export const deleteUser = (id) => {
  return fetch(`${API_URL}/api/users/${id}`, {
    method: 'DELETE',
    headers: getAuthHeaders()
  }).then(response => response.json());
}

// ... các hàm khác

// API to reset user password
export const resetUserPassword = (id) => {
  return fetch(`${API_URL}/api/users/${id}/reset-password`, {
    method: 'POST',
    headers: getAuthHeaders()
  }).then(response => response.json());
}
// API to get groups
export const getGroups = () => {
  return fetch(`${API_URL}/api/tagGroups`, {
    method: 'GET',
    headers: getAuthHeaders()
  }).then(response => response.json());
}

// API to create group
export const createGroup = (group) => {
  return fetch(`${API_URL}/api/tagGroups`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(group)
  }).then(response => response.json());
}

// API to update group
export const updateGroup = (id, group) => {
  return fetch(`${API_URL}/api/tagGroups/${id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(group)
  }).then(response => response.json());
}

// API to delete group
export const deleteGroup = (id) => {
  return fetch(`${API_URL}/api/tagGroups/${id}`, {
    method: 'DELETE',
    headers: getAuthHeaders()
  }).then(response => response.json());
}