import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import AppMenu from './components/AppMenu';
import CompanyManagement from './screens/CompanyManagement';
import GroupManagement from './screens/GroupManagement';
import TagManagement from './screens/TagManagement';
import UserManagement from './screens/UserManagement';
import TagDetailScreen from './screens/TagDetailScreen';

import NotFoundScreen from './screens/NotFoundScreen';
import LoginScreen from './screens/LoginScreen';
import StoreManager from './appdata';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import { Layout, Button, Image, Avatar, Dropdown } from 'antd';

import './App.css';
import resource from './resource';

StoreManager.loadDataFromLocal();

const { Header, Sider, Content } = Layout;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      isLogin: StoreManager.userdata.token !== ''
    };
    StoreManager.rootState.thisApp = this;
  }

  render() {
    const { collapsed, isLogin } = this.state;
    if (isLogin) {
      return (
        <Layout style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
          <Header style={{ padding: 0, background: 'white', boxShadow: '0 2px 8px rgba(0,0,0,0.1)' }}>
            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ marginLeft: collapsed ? 8 : 4 }}>
                <Button
                  type="text"
                  icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                  onClick={() => this.setState({ 'collapsed': !collapsed })}
                  style={{ fontSize: '16px', width: 64, height: 64 }}
                />
                <Image style={{ marginLeft: 15 }} width={100} src={resource.images.logo} preview={false} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: '1',
                        icon: <UserOutlined />,
                        label: (
                          <span onClick={() => {}}>
                            {`Account`}
                          </span>
                        ),
                      },
                      {
                        key: '2',
                        icon: <LogoutOutlined />,
                        label: (
                          <span onClick={() => {
                            StoreManager.userdata.token = '';
                            this.setState({ 'isLogin': false });
                          }}>
                            {`Logout`}
                          </span>
                        ),
                      },
                    ],
                  }}
                >
                  <span className="user-action">
                    <Avatar style={{ backgroundColor: 'green' }} size="default">
                      {`A D`}
                    </Avatar>
                  </span>
                </Dropdown>
              </div>
            </div>
          </Header>
          <Layout>
            <Sider trigger={null} collapsible collapsed={collapsed} theme="light">
              <AppMenu collapsed={collapsed} />
            </Sider>
            <Content style={{ margin: '16px 16px', padding: 24, minHeight: 280, background: 'white', borderRadius: 5 }}>
              <Switch>
                <Route exact path="/" component={CompanyManagement} />
                <Route exact path="/users" component={UserManagement} />
                <Route path="/companies" component={CompanyManagement} />
                <Route path="/groups" component={GroupManagement} />
                <Route path="/tags/:tagId" component={TagDetailScreen} />
                <Route path="/tags" component={TagManagement} />
                <Route component={NotFoundScreen} />
              </Switch>
            </Content>
          </Layout>
        </Layout>
      );
    } else {
      return <LoginScreen />;
    }
  }
}

export default App;
