import React from 'react';
import { Menu, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { AppstoreOutlined, UsergroupAddOutlined, TagsOutlined, ShopOutlined } from '@ant-design/icons';
import StoreManager from '../appdata';

const { Text } = Typography;
const { version } = require('../../package.json');

const AppMenu = ({ collapsed }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
      <Menu theme="light" mode="inline">
        <Menu.Item key="1" icon={<AppstoreOutlined />}>
          <Link to="/">Dashboard</Link>
        </Menu.Item>
        {
          StoreManager.userdata.jwtDecode?.role === "superadmin" ? (
            <Menu.Item key="2" icon={<ShopOutlined />}>
              <Link to="/companies">Company Management</Link>
            </Menu.Item>
          ) : null
        }
         {
          StoreManager.userdata.jwtDecode?.role != "user" ? (
            <Menu.Item key="3" icon={<UsergroupAddOutlined />}>
            <Link to="/users">User Management</Link>
          </Menu.Item>
          ) : null
        }
   
        <Menu.Item key="4" icon={<UsergroupAddOutlined />}>
          <Link to="/groups">Group Management</Link>
        </Menu.Item>
        <Menu.Item key="5" icon={<TagsOutlined />}>
          <Link to="/tags">Tag Management</Link>
        </Menu.Item>
      </Menu>
      <Text style={{ margin: "20px 20px", display: collapsed ? "none" : "" }}>{version}</Text>
    </div>
  );
}

export default AppMenu;
