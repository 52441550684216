import React from 'react';
import { Table, Button, Modal, Form, Input, Select, Popconfirm, Breadcrumb, message } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, HomeOutlined, TeamOutlined } from '@ant-design/icons';
import { getGroups, createGroup, updateGroup, deleteGroup, getCompanies } from '../apis'; // Import necessary API functions
import StoreManager from '../appdata';

const { Option } = Select;

class GroupManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      companies: [],
      isModalVisible: false,
      editingGroup: null,
    };
  }

  componentDidMount() {
    StoreManager.rootState.thisGroupManagement = this;
    this.fetchGroups();
    this.fetchCompanies();
  }

  fetchGroups = async () => {
    try {
      const groups = await getGroups();
      groups.reverse();
      this.setState({ groups });
    } catch (error) {
      console.error('Error fetching groups:', error);
    }
  }

  fetchCompanies = async () => {
    try {
      const companies = await getCompanies();
      this.setState({ companies });
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  }

  showModal = (group) => {
    this.setState({
      isModalVisible: true,
      editingGroup: group,
    }, () => {
      const initialValues = {
        group_name: group ? group.group_name : '',
        company: group ? group.company : null,
      };
      this.formRef.current.setFieldsValue(initialValues);
    });
  };

  handleOk = async () => {
    const form = this.formRef.current;
    const values = await form.validateFields();
    console.log("values", values)

    try {
      if (this.state.editingGroup) {
        await updateGroup(this.state.editingGroup._id, values);
      } else {
        await createGroup(values);
      }
      this.setState({ isModalVisible: false, editingGroup: null });
      this.fetchGroups();
    } catch (error) {
      console.error('Error saving group:', error);
    }
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      editingGroup: null,
    });
  };

  deleteGroup = async (id) => {
    try {
      await deleteGroup(id);
      this.fetchGroups();
    } catch (error) {
      console.error('Error deleting group:', error);
    }
  };

  formRef = React.createRef();

  getCompanyName = (companyId) => {
    if (!Array.isArray(this.state.companies)) return 'Unknown';
    const company = this.state.companies.find(company => company._id === companyId);
    return company ? company.company_name : 'Unknown';
  }

  render() {
    const columns = [
      { title: 'Group Name', dataIndex: 'group_name', key: 'group_name' },
      { 
        title: 'Company', 
        dataIndex: 'company_id',
        key: 'company_id',
        render: (text, record) => {
          const companyName = this.getCompanyName(record.company_id);
          return companyName;
        }
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Button type="primary" ghost icon={<EditOutlined />} onClick={() => this.showModal(record)} style={{ marginRight: 10 }} />
            <Popconfirm
              title="Are you sure delete this group?"
              onConfirm={() => this.deleteGroup(record._id)}
              okText="Yes"
              cancelText="No"
            >
              <Button danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </span>
        ),
      },
    ];
    let scrollY = window.innerHeight - 300

    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 14 }}>
          <Breadcrumb
            items={[
              {
                href: '',
                title: <> <HomeOutlined />
                  <span>Home</span>
                </>,
              },
              {
                title: (
                  <>
                    <TeamOutlined />
                    <span>Groups</span>
                  </>
                ),
              }
            ]}
          />
          <Button type="primary" icon={<PlusOutlined />} onClick={() => this.showModal(null)}>Add Group</Button>
        </div>
        <Table scroll={{ y: scrollY }} bordered dataSource={this.state.groups} columns={columns} rowKey="_id" />
        <Modal
          title={this.state.editingGroup ? "Edit Group" : "Add Group"}
          visible={this.state.isModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Form
            layout="vertical"
            ref={this.formRef}
            initialValues={{ group_name: '', company_id: null }}
          >
            <Form.Item
              label="Group Name"
              name="group_name"
              rules={[{ required: true, message: 'Please input the group name!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Company"
              name="company_id"
              rules={[{ required: true, message: 'Please select the company!' }]}
            >
              <Select>
                {(this.state.companies || []).map(company => (
                  <Option key={company._id} value={company._id}>
                    {company.company_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default GroupManagement;
