import React, { Component } from 'react';
import { Table, Descriptions, Breadcrumb } from 'antd';
import { HomeOutlined, TagOutlined } from '@ant-design/icons';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

import { getLogsByTagId, getTagById } from '../apis';

// Đăng ký các thành phần cần thiết của Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

class TagDetailScreen extends Component {
  state = {
    tag: {},
    logs: [],
  };

  componentDidMount() {
    const { tagId } = this.props.match.params;

    this.fetchTagDetails(tagId);
    this.fetchTagLogs(tagId);
  }

  fetchTagDetails = async (tagId) => {
    try {
      const tag = await getTagById(tagId);
      this.setState({ tag });
    } catch (error) {
      console.error('Error fetching tag details:', error);
    }
  };

  fetchTagLogs = async (tagId) => {
    try {
      const fetchedLogs = await getLogsByTagId(tagId);
      // Sử dụng một đối tượng Map để nhóm logs
      const logsMap = new Map();
      fetchedLogs.forEach(log => {
        const key = new Date(log.op_time).toISOString();
        if (!logsMap.has(key)) {
          logsMap.set(key, { ...log, children: [] }); // Luôn khởi tạo children như một mảng
        } else {
          logsMap.get(key).children.push(log);
        }
      });
      const groupedLogs = Array.from(logsMap.values());
      // Đảm bảo rằng mỗi nhóm log có thuộc tính children là một mảng
      groupedLogs.forEach(log => {
        if (!log.children) {
          log.children = [];
        }
      });
      this.setState({ logs: groupedLogs });
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  };
  
  
  prepareChartData = () => {
    const { logs } = this.state;

    const labels = logs.map(log => new Date(log.op_time).toLocaleString());
    const data = logs.map(log => parseFloat(log.decimal_temp));

    return {
      labels,
      datasets: [
        {
          label: 'Temperature Over Time',
          data: data,
          borderColor: 'rgba(75,192,192,1)',
          backgroundColor: 'rgba(75,192,192,0.2)',
          fill: false,
        },
      ],
    };
  };

  render() {
    const { tag, logs } = this.state;

    const columns = [
      { title: 'Operation Time', dataIndex: 'op_time', key: 'op_time', sorter: (a, b) => new Date(a.op_time) - new Date(b.op_time) },
      { title: 'Temp ID', dataIndex: 'temp_id', key: 'temp_id' },
      { title: 'Hex Temp', dataIndex: 'hex_temp', key: 'hex_temp' },
      { title: 'Decimal Temp', dataIndex: 'decimal_temp', key: 'decimal_temp' },
      { title: 'Field Flag', dataIndex: 'field_flag', key: 'field_flag' },
      { title: 'Latitude', dataIndex: 'lat', key: 'lat' },
      { title: 'Longitude', dataIndex: 'long', key: 'long' },
    ];

    const maxHeight = window.innerHeight - 180;

    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 14 }}>
          <Breadcrumb
            items={[
              {
                href: '/',
                title: <> <HomeOutlined />
                  <span>Home</span>
                </>,
              },
              {
                href: '/tags',
                title: (
                  <>
                    <TagOutlined />
                    <span>Tags</span>
                  </>
                ),
              },
              {
                title: 'Tag Detail',
              }
            ]}
          />
        </div>
        <div
          style={{
            maxHeight: maxHeight,
            overflowY: 'auto',
            padding: 20,
            borderRadius: 8,
          }}
        >
          <Descriptions title="Tag Details" bordered>
            <Descriptions.Item label="Tag ID">{tag.tag_id}</Descriptions.Item>
            <Descriptions.Item label="Tag Type">{tag.tag_type}</Descriptions.Item>
            <Descriptions.Item label="Status">{tag.status}</Descriptions.Item>
            <Descriptions.Item label="Operation Status">{tag.opStatus}</Descriptions.Item>
            <Descriptions.Item label="Start Time">{tag.startTime}</Descriptions.Item>
            <Descriptions.Item label="Total Count">{tag.totalCount}</Descriptions.Item>
          </Descriptions>

        
          <Table
        scroll={{ y: maxHeight - 300 }}
        style={{ marginTop: 20 }}
        bordered
        dataSource={logs}
        columns={columns}
        rowKey="_id"
        defaultSortOrder="descend"

        expandable={{
          expandedRowRender: record => (
            record.children && record.children.length > 0 ? (
              <Table
                columns={columns}
                dataSource={record.children}
                pagination={false}
              />
            ) : null
          ),
          rowExpandable: record => record.children && record.children.length > 0,
        }}
        
      />

          <div style={{ marginTop: 20 }}>
            <h3>Temperature Over Time</h3>
            <Line data={this.prepareChartData()} />
          </div>
        </div>
      </div>
    );
  }
}

export default TagDetailScreen;
