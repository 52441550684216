import React from 'react';
import { Table, Button, Modal, Form, Input, Select, Popconfirm, Breadcrumb } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, HomeOutlined, TagOutlined, EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { getTags, createTag, updateTag, deleteTag, getGroups, getCompanies } from '../apis';
import StoreManager from '../appdata';

const { Option } = Select;

class TagManagement extends React.Component {
  state = {
    tags: [],
    groups: [],
    companies: [],
    isModalVisible: false,
    editingTag: null,
  };

  componentDidMount() {
    StoreManager.rootState.thisTagManagement = this;
    this.fetchTags();
    this.fetchGroups();
    this.fetchCompanies();
  }

  fetchTags = async () => {
    try {
      const tags = await getTags();
      tags.reverse();
      this.setState({ tags });
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  }

  fetchGroups = async () => {
    try {
      const groups = await getGroups();
      this.setState({ groups });
    } catch (error) {
      console.error('Error fetching groups:', error);
    }
  }

  fetchCompanies = async () => {
    try {
      const companies = await getCompanies();
      this.setState({ companies });
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  }

  showModal = (tag) => {
    this.setState({
      isModalVisible: true,
      editingTag: tag,
    }, () => {
      const initialValues = {
        tag_id: tag ? tag.tag_id : '',
        tag_type: tag ? tag.tag_type : 'TAG_NFC1',
        status: tag ? tag.status : 'NEW',
        group_id: tag ? tag.group_id._id : null,
        opStatus: tag ? tag.opStatus : '',
        startTime: tag ? tag.startTime : '',
        totalCount: tag ? tag.totalCount : '',
        recordedCount: tag ? tag.recordedCount : '',
        delayMinutes: tag ? tag.delayMinutes : '',
        intervalSeconds: tag ? tag.intervalSeconds : '',
        recordedMinimum: tag ? tag.recordedMinimum : '',
        recordedMaximum: tag ? tag.recordedMaximum : '',
        overLowCount: tag ? tag.overLowCount : '',
        overHighCount: tag ? tag.overHighCount : '',
        validMinimum: tag ? tag.validMinimum : '',
        validMaximum: tag ? tag.validMaximum : '',
      };
      this.formRef.current.setFieldsValue(initialValues);
    });
  };



  handleOk = async () => {
    const form = this.formRef.current;
    const values = await form.validateFields();
    console.log("values", values);

    try {
      if (this.state.editingTag) {
        await updateTag(this.state.editingTag._id, values);
      } else {
        await createTag(values);
      }
      this.setState({ isModalVisible: false, editingTag: null });
      this.fetchTags();
    } catch (error) {
      console.error('Error saving tag:', error);
    }
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      editingTag: null,
    });
  };


  deleteTag = async (id) => {
    try {
      await deleteTag(id);
      this.fetchTags();
    } catch (error) {
      console.error('Error deleting tag:', error);
    }
  };

  formRef = React.createRef();

  getGroupName = (groupId) => {
    const group = this.state.groups.find(group => group._id === groupId);
    return group ? group.group_name : 'Unknown';
  }

  getCompanyNameByGroupId = (groupId) => {
    const group = this.state.groups.find(group => group._id === groupId);
    if (!group) return 'Unknown';
    const company = this.state.companies.find(company => company._id === group.company_id);
    return company ? company.company_name : 'Unknown';
  }

  formatDateTime = (timestamp) => {
    if (timestamp === 0) return '0';
    const date = new Date(timestamp * 1000);
    const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
    return `${formattedTime}\n${formattedDate}`;
  }

  render() {
    const columns = [
      { title: 'Tag ID', dataIndex: 'tag_id', key: 'tag_id', width: 100 },
      { title: 'Tag Type', dataIndex: 'tag_type', key: 'tag_type', width: 100 },
      { title: 'Status', dataIndex: 'status', key: 'status', width: 100 },
      { title: 'Operation Status', dataIndex: 'opStatus', key: 'opStatus', width: 100 },
      { 
        title: 'Start Time', 
        dataIndex: 'startTime', 
        key: 'startTime', 
        width: 100,
        render: (text, record) => this.formatDateTime(record.startTime) 
      },
      { title: 'Total Count', dataIndex: 'totalCount', key: 'totalCount', width: 100 },
      { title: 'Recorded Count', dataIndex: 'recordedCount', key: 'recordedCount', width: 100 },
      { title: 'Delay Minutes', dataIndex: 'delayMinutes', key: 'delayMinutes', width: 100 },
      { title: 'Interval Seconds', dataIndex: 'intervalSeconds', key: 'intervalSeconds', width: 100 },
      { title: 'Recorded Minimum', dataIndex: 'recordedMinimum', key: 'recordedMinimum', width: 100 },
      { title: 'Recorded Maximum', dataIndex: 'recordedMaximum', key: 'recordedMaximum', width: 100 },
      { title: 'Over Low Count', dataIndex: 'overLowCount', key: 'overLowCount', width: 100 },
      { title: 'Over High Count', dataIndex: 'overHighCount', key: 'overHighCount', width: 100 },
      { title: 'Valid Minimum', dataIndex: 'validMinimum', key: 'validMinimum', width: 100 },
      { title: 'Valid Maximum', dataIndex: 'validMaximum', key: 'validMaximum', width: 100 },
      { 
        title: 'Group | Company', 
        key: 'group_id',
        width: 150,
        render: (text, record) => {
          const groupName = record.group_id ? this.getGroupName(record.group_id._id) : 'Unknown';
          const companyName = record.group_id ? this.getCompanyNameByGroupId(record.group_id._id) : 'Unknown';          
          return `${groupName} | ${companyName}`;
        }
      },
      {
        title: 'Action',
        key: 'action',
        width: 150,
        fixed: 'right',
        render: (text, record) => (
          <span>
            <Button type="primary" ghost icon={<EditOutlined />} onClick={() => this.showModal(record)} style={{ marginRight: 10 }} />
            <Popconfirm
              title="Are you sure delete this tag?"
              onConfirm={() => this.deleteTag(record._id)}
              okText="Yes"
              cancelText="No"
            >
              <Button danger icon={<DeleteOutlined />} style={{ marginRight: 10 }} />
            </Popconfirm>
            {/* <Button type="default" icon={<EyeOutlined />} onClick={() => this.showViewModal(record)} /> */}
            <Link to={`/tags/${record._id}`}>
              <Button type="default" icon={<EyeOutlined />} />
            </Link>
          </span>
        ),
      },
    ];
    let scrollY = window.innerHeight - 300

    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 14 }}>
          <Breadcrumb
            items={[
              {
                href: '',
                title: <> <HomeOutlined />
                  <span>Home</span>
                </>,
              },
              {
                title: (
                  <>
                    <TagOutlined />
                    <span>Tags</span>
                  </>
                ),
              }
            ]}
          />
          <Button type="primary" icon={<PlusOutlined />} onClick={() => this.showModal(null)}>Add Tag</Button>
        </div>
        <Table 
          scroll={{ y: scrollY, x: 2000 }}
          bordered 
          dataSource={this.state.tags} 
          columns={columns} 
          rowKey="_id" 
        />
        <Modal
          title={this.state.editingTag ? "Edit Tag" : "Add Tag"}
          visible={this.state.isModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Form
            layout="vertical"
            ref={this.formRef}
            initialValues={{ 
              tag_id: '', 
              tag_type: 'TAG_NFC1', 
              status: 'NEW', 
              group_id: null, 
              opStatus: '',
              startTime: '',
              totalCount: '',
              recordedCount: '',
              delayMinutes: '',
              intervalSeconds: '',
              recordedMinimum: '',
              recordedMaximum: '',
              overLowCount: '',
              overHighCount: '',
              validMinimum: '',
              validMaximum: ''
            }}
          >
            <Form.Item
              label="Tag ID"
              name="tag_id"
              rules={[{ required: true, message: 'Please input the tag ID!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Tag Type"
              name="tag_type"
              rules={[{ required: true, message: 'Please select the tag type!' }]}
            >
              <Select>
                <Option value="TAG_NFC1">TAG_NFC1</Option>
                <Option value="TAG_NFC2">TAG_NFC2</Option>
                <Option value="TAG_NFC3">TAG_NFC3</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Status"
              name="status"
              rules={[{ required: true, message: 'Please select the status!' }]}
            >
              <Select>
                <Option value="NEW">NEW</Option>
                <Option value="ACTIVE">ACTIVE</Option>
                <Option value="EXPIRE">EXPIRE</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Group"
              name="group_id"
              rules={[{ required: true, message: 'Please select the group!' }]}
            >
              <Select>
                {this.state.groups.map(group => (
                  <Option key={group._id} value={group._id}>
                    {group.group_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Operation Status"
              name="opStatus"
              rules={[{ required: true, message: 'Please input the operation status!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Start Time"
              name="startTime"
              rules={[{ required: true, message: 'Please input the start time!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Total Count"
              name="totalCount"
              rules={[{ required: true, message: 'Please input the total count!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Recorded Count"
              name="recordedCount"
              rules={[{ required: true, message: 'Please input the recorded count!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Delay Minutes"
              name="delayMinutes"
              rules={[{ required: true, message: 'Please input the delay minutes!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Interval Seconds"
              name="intervalSeconds"
              rules={[{ required: true, message: 'Please input the interval seconds!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Recorded Minimum"
              name="recordedMinimum"
              rules={[{ required: true, message: 'Please input the recorded minimum!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Recorded Maximum"
              name="recordedMaximum"
              rules={[{ required: true, message: 'Please input the recorded maximum!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Over Low Count"
              name="overLowCount"
              rules={[{ required: true, message: 'Please input the over low count!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Over High Count"
              name="overHighCount"
              rules={[{ required: true, message: 'Please input the over high count!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Valid Minimum"
              name="validMinimum"
              rules={[{ required: true, message: 'Please input the valid minimum!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Valid Maximum"
              name="validMaximum"
              rules={[{ required: true, message: 'Please input the valid maximum!' }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
    
      </div>
    );
  }
}

export default TagManagement;
